@import './variables.module.less';
@import '@tencent/coral-style/dist/variables.less';

// 清除浮动
.clearfix() {
  zoom: 1;

  &::before,
  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
  }
}

//设置滚动条样式
.setScrollbarStyle(@width: 10px; @color: rgba(0, 0, 0, 0.15); @hoverColor: rgba(0, 0, 0, 0.2)) {
  -webkit-overflow-scrolling: touch;

  /** 火狐浏览器隐藏滚动条*/
  scrollbar-width: none;

  /* 整个滚动条 */
  &::-webkit-scrollbar {
    appearance: none;
    width: @width;
    height: @width;
    background-color: rgba(0,0,0,0);
  }

  /* 滚动条上的滚动滑块. */
  &::-webkit-scrollbar-thumb {
    background-color: @color;
    border-radius: @width / 2;
    
    &:hover,
    &:focus {
      background-color: @hoverColor;
    }
  }
}

/* 
  主体内容通用样式
    - 限制最大宽度 1366px
    - 限制最小宽度 1024px
    - 限制安全间距 24px
*/
.container(@padding-h: @spacer-3) {
  margin: 0 auto;
  max-width: @container-max-width;
  min-width: @container-min-width;
  padding: 0 @padding-h;
}

.mobileContainer(@padding-h: 0) {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  padding: 0 @padding-h;
}

.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; //英文或数字换行
}

.multi-ellipsis(@rows: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; //英文或数字换行

  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: @rows;
  -webkit-box-orient: vertical;
}

/*======== 通用标题 ========*/
.common-title-h3() {
  .flex-x-b();
  font-size: 18px;
  color: #000;
  margin-bottom: @spacer-2;
  margin-top: @spacer-4;
}

// 链接样式
.link(@color: #666, @rules: {}) {
  color: @color;
  fill: @color;
  text-decoration: none;
  transition: color @trans-time, fill @trans-time;
  
  &:hover {
    color: @brand-color-hover;
    fill: @brand-color-hover;
  }

  &:active {
    color: @brand-color-active;
    fill: @brand-color-active;
  }

  &:focus {
    text-decoration: none;
  }
  
  /* stylelint-disable-next-line order/order */
  @rules();
}

/*
* 移动端 vw 布局
* @px: 设计稿上元素的宽度
* @viewportWidth：设计稿的宽度 375/750...
* eg: .vw(182)[@rem]
*/
.vw(@px, @viewportWidth: 375) {
  @var: (@px / @viewportWidth) * 100;
  @rem: ~'@{var}vw';
}

// flex布局
.flex-x-a(@align-items: center) {
  display: flex;
  align-items: @align-items;
  justify-content: space-around;
}

.flex-x-b(@align-items: center) {
  display: flex;
  align-items: @align-items;
  justify-content: space-between;
}

.flex-x-c(@align-items: center) {
  display: flex;
  align-items: @align-items;
  justify-content: center;
}

.flex-x-e(@align-items: center) {
  display: flex;
  align-items: @align-items;
  justify-content: flex-end;
}

.flex-x-s(@align-items: center) {
  display: flex;
  align-items: @align-items;
  justify-content: flex-start;
}

// 工作台收藏内容区域滚动，子导航毛玻璃效果
.filter-background {
  background-color: rgba(246, 246, 246, .8);
  backdrop-filter: blur(20px);
}