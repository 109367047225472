@import '../../style/mixins.less';

@error-status-prefix: ~'@{prefix-cls}-error-status';

.@{error-status-prefix} {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;


  &__icon {
    width: 400px;
    text-align: center;
    font-size: 0;

    img {
      max-width: 100%;
    }
  }

  &__desc {
    padding: 16px;
    font-size: 16px;
    color: @text-color;
  }

  &__extra {
    .flex-x-c();
    .@{error-status-prefix}__link {
      color: @primary-color;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &-fallback {
    .@{error-status-prefix}__icon {
      width: 500px;
    }
  }
}
@primary-color: #2378ff;@link-color: #666666;@text-color: #333333;@border-radius-base: 4px;@modal-header-padding: 32px 24px;@modal-body-padding: 40px 24px 40px 24px;@modal-footer-padding-vertical: 32px;@modal-footer-padding-horizontal: 24px;@modal-header-close-size: 87px;@modal-header-title-font-size: 20px;@checkbox-border-radius: var(--coral-radius-small);@btn-icon-text-margin-left: 6px;@pagination-bg-color-hover: var(--td-brand-color-hover);@pagination-bg-color-active: var(--td-brand-color-active);