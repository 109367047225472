// -------- 通用类名前缀 --------
@prefix-cls: 'res';
// -------- Colors --------
@primary-color: #2378ff;            // 主题色
@primary-color-hover: #4f93ff;      // 主题色hover
@primary-color-active: #0085ee;     // 主题色active
@warning-color: #ff9b24;            // 警告
@error-color: #ef3b2d;              // 失败｜错误
@success-color: #1ed26e;            // 成功
@disable-color: #e2e2e2;            // 禁用

// -------- Background --------
@background-color: #f5f5f5;
@background-color-hover: fade(@primary-color, 10%);

// -------- Text --------
@heading-color: #000000;          // 标题黑
@text-color: #333333;             // 正文黑 #000000 80%
@text-color-1: #999999;           // 次级灰 #000000 40%
@text-color-2: #cccccc;           // 弱浅灰 #000000 20%
@text-color-placeholder: #b2b4b7; // 占位符

// -------- Link --------
@link-color: #666666;         // 链接
@link-color-hover: @primary-color-hover;   // 链接-hover

// -------- Container --------
@container-min-width: 1024px;
@container-max-width: 1366px;

// 阅读舒适宽度
@readable-max-width: 1180px;

@sidebar-width: 314px; //  侧边栏宽度：个人空间/团队空间/空间推荐页/推荐页/文章、报告详情页 侧边栏的宽度
@sidebar-width-sm: 260px;


// -------- zIndex --------

/*
* 图库浏览器组件 zIndex 100
* 参考 bootstrap 为组件设定的 z-index 比例
* https://v4.bootcss.com/docs/layout/overview/#z-index
*/
@zindex-dropdown: 10;
@zindex-sticky: 20;
@zindex-fixed: 30;
@zindex-modal-backdrop: 40;
@zindex-modal: 50;
@zindex-popover: 60;
@zindex-tooltip: 70;

// ------- shadow --------
@default-shadow: 0 2px 8px 0 rgba(0, 0, 0, .06);
@hover-shadow: 0px 4px 16px rgba(0, 0, 0, .08);

// ------- border -------
@border-color: #e5e5e5;
@default-border: 1px solid @border-color; 
@main-border-radius: 4px;
@small-border-radius: 4px;

// -------- Transition --------
@trans-time: .3s;
@trans-function: ease;

// -------- Header --------
@header-height-default: 80px; // 头部默认高度
@header-height-small: 64px; // 头部高度（小）
@page-header-height: @header-height-small; // 调研云头部高度

// -------- Badge --------
@badge-color: #f56c6c;

// -------- Spacing --------
@spacing: 24px;         // 间距           
@spacing-small: 16px;   // 间距-小

// -------- mask --------
@mask-color: rgba(0,0,0,.4); // mask背景色


// -------- 从问卷同步的变量 --------

@g-max-width: 1512px; // 适配的最大内容宽度
@width-small: 980px;
@width-medium: 1251px;
@pad-default: 108px; // 默认的内容边距
@pad-small: 20px; // 极小屏时的边距

// 常用的一个对于内容padding的响应变化
.media-content-pad() {
  @media (min-width: (@width-medium + 1)) { 
    padding-left: @pad-default; 
    padding-right: @pad-default;
  }

  @media (min-width: (@width-small + 1)) { 
    padding-left: @pad-small; 
    padding-right: @pad-small;
  }
}


// 输出less变量，可直接在ts中使用
:export {

  primaryColor: @primary-color;
  primaryColorHover: @primary-color-hover;    
  primaryColorActive: @primary-color-active;  
  
  warningColor: @warning-color;            
  errorColor: @error-color;              
  successColor: @success-color;            
  disableColor: @disable-color; 
  
  headingColor: @heading-color;          
  textColor: @text-color;             
  textColor1: @text-color-1;          
  textColor2: @text-color-2;        
  textColorPlaceholder: @text-color-placeholder; 

  linkColor: @link-color;
  linkColorHover: @link-color-hover;

  borderRadius: @main-border-radius;
  borderColor: @border-color;
  border: @default-border;

  spacing: @spacing;
  spacingSmall: @spacing-small;

  maxWidth: @container-max-width;
  minWidth: @container-min-width;
}
