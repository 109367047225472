@import '../../style/mixins.less';

@prefixCls: ~'@{prefix-cls}-info-setting-dialog';

.@{prefixCls} {
  .t-dialog__body {
    padding: 0;
  }
}

@primary-color: #2378ff;@link-color: #666666;@text-color: #333333;@border-radius-base: 4px;@modal-header-padding: 32px 24px;@modal-body-padding: 40px 24px 40px 24px;@modal-footer-padding-vertical: 32px;@modal-footer-padding-horizontal: 24px;@modal-header-close-size: 87px;@modal-header-title-font-size: 20px;@checkbox-border-radius: var(--coral-radius-small);@btn-icon-text-margin-left: 6px;@pagination-bg-color-hover: var(--td-brand-color-hover);@pagination-bg-color-active: var(--td-brand-color-active);