@import '../../style/mixins.less';

@prefixCls: ~'@{prefix-cls}-info-setting-dialog-content';
// 头像上传容器宽度
@cropper-width: 200px;

.@{prefixCls} {
  &__body {
    padding: @spacer-2 @spacer-4;
    min-height: 230px;
  }

  &__avatar {
    width: 80px;
    margin: @spacer-4 auto;
    position: relative;
    margin-bottom: @spacer-2;
  }

  &__name {
    width: 250px;
    margin: 0 auto;
  }

  // 编辑头像按钮
  &__btn-edit-avatar {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 20px;
    background-color: #fff;
    border-color: #fff;
    color: @text-color-placeholder;
  }

  &__edit-content {
    .flex-x-c();
    margin-top: @spacer-4;

    
  }

  // 上传头像
  &__upload-avatar {
    .flex-x-s();
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    

    .coral-cropper {
      &__upload-container {
        .flex-x-c();
        margin-right: 0;
        width: @cropper-width;
      }

      &__comp-container {
        height: 100px;
        width: @cropper-width;
      }

      &__comp-operation-container {
        margin-right: 0;
        width: @cropper-width;
      }

      &__btns-container {
        display: none;
      }

      &__preview-container {
        margin-left: 70px;
        margin-top: 10px;
      }
    }

    .upload-buttons {
      .flex-x-c();
      width: @cropper-width;
      margin-top: @spacer
    }
  }

  &__footer {
    .flex-x-e();
    gap: @spacer;
    border-top: 1px solid @component-border;
    padding: @spacer-2 @spacer-3;
  }
}

@primary-color: #2378ff;@link-color: #666666;@text-color: #333333;@border-radius-base: 4px;@modal-header-padding: 32px 24px;@modal-body-padding: 40px 24px 40px 24px;@modal-footer-padding-vertical: 32px;@modal-footer-padding-horizontal: 24px;@modal-header-close-size: 87px;@modal-header-title-font-size: 20px;@checkbox-border-radius: var(--coral-radius-small);@btn-icon-text-margin-left: 6px;@pagination-bg-color-hover: var(--td-brand-color-hover);@pagination-bg-color-active: var(--td-brand-color-active);