@import '../../../style/mixins.less';

body {
  background-color: @background-color;
  color: @text-color;
  font-family: @font-family;
  font-size: @font-size-base;
  line-height: @coral-line-height-base;
  -webkit-font-smoothing: antialiased;
}

/* stylelint-disable-next-line plugin/selector-tag-no-without-class */
ul,
dl,
li,
dd,
dt {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* stylelint-disable-next-line plugin/selector-tag-no-without-class */
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
  margin: 0;
}

a {
  color: #666;
  text-decoration: none;
  transition: color @trans-time;

  &:hover {
    color: @brand-color;
  }

  &:active {
    color: @brand-color-active;
  }

  &:focus {
    text-decoration: none;
  }
}

a.coral-button--primary.coral-button--primary-fill:hover,
a.coral-button--primary.coral-button--primary-fill:focus {
  // 珊瑚链接按钮hover状态样式
  color: #fff;
}

// button
.coral-button {
  .coral-button__icon-loading {
    width: 16px;
    height: 16px;
    margin: -2px 0 0;

    & + .coral-button__icon-prefix {
      display: none;
    }
  }
}

// checkbox
.ten-checkbox__checkbox-inner::after {
  box-sizing: content-box;
}

.coral-cropper__upload-container .coral-cropper__show-container .t-button--variant-text {
  height: 100%;
  padding: 0;
  display: block;
  border: 0;
}

.coral-workspace-list-view-controls {
  box-sizing: content-box;
}

.coral-workspace-sort-controls-btn {
  border-radius: 0;
}

// td dialog 添加此类名适配最新的弹窗设计稿

.t-dialog__wrap {
  .t-dialog {
    border: 0;
    box-shadow: @coral-shadow-upper;
  }
}

.t-dialog {
  padding: 0;

  &__header {
    height: @spacer-7;
    padding: 0 @spacer-3;
    border-bottom: 1px solid @border-level-1-color;
    .ellipsis();

    &:empty {
      height: auto;
      padding: 0;
      border: 0;
    }
  }

  &__close {
    top: calc(@spacer-7 / 2 - 15px);
    right: 14px;
    width: 30px;
    height: 30px;
    font-size: 22px;
    color: @text-color-primary;
  }

  &__body {
    padding: @spacer-3;
    color: @text-color-primary;
  }

  &__footer {
    height: @spacer-7;
    padding: 0 @spacer-3;
    word-spacing: 0;
    line-height: @spacer-7;
    border-top: 1px solid @border-level-1-color;

    .t-button {
      vertical-align: 0;

      & + .t-button {
        margin-left: @spacer-2;
      }
    }
  }
}

.t-avatar {
  box-sizing: border-box;
}

/** td评论组件头像 */
.t-comment__avatar {
  align-items: flex-start;
}

/* td禁用状态按钮在safari下背景色失效 */
.t-button.t-is-disabled {
  appearance: none;
}

/* 修复td button + href之后在safari下背景色失效 */
a.t-button {
  /* stylelint-disable-next-line declaration-no-important */
  appearance: none !important;
}

/* 覆盖 TD Tag 组件选中文字的背景色 */
.t-tag {
  border-radius: @small-border-radius;
  
  ::selection {
    background-color: @primary-color;
  }
}


/* 头部导航菜单样式 */
.coral-header-nav-dropdown {
  &__button {
    padding: 0 @spacer-s;
    margin: 0 -@spacer-s;
    flex-shrink: 0;

    .t-button__text {
      align-items: center;
    }
  }

  &__menu {
    .t-dropdown__item-text {
      padding: 0 @spacer;
      line-height: @spacer-5;
    }
  }
}

/* 修复 TD Pagination 组件跳转指定页append文案边框 */
.t-pagination {
  .t-input-adornment__append {
    .t-input-adornment__text {
      border: 0;
    }
  }
}
@primary-color: #2378ff;@link-color: #666666;@text-color: #333333;@border-radius-base: 4px;@modal-header-padding: 32px 24px;@modal-body-padding: 40px 24px 40px 24px;@modal-footer-padding-vertical: 32px;@modal-footer-padding-horizontal: 24px;@modal-header-close-size: 87px;@modal-header-title-font-size: 20px;@checkbox-border-radius: var(--coral-radius-small);@btn-icon-text-margin-left: 6px;@pagination-bg-color-hover: var(--td-brand-color-hover);@pagination-bg-color-active: var(--td-brand-color-active);